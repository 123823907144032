import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { HealthHomeGuard } from './guards/health-home.guard';
import { RouteNames } from 'src/app/constants/route-names';
import { PermissionGuard } from './guards/permission.guard';

const routes: Routes = [
  {
    path: RouteNames.login.path,
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
    data: {
      name: RouteNames.login.name,
      metaTitle: '',
      hideToolbars: true
    }
  },
  {
    path: RouteNames.forgotPassword.path,
    loadChildren: () => import('./features/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    data: {
      name: RouteNames.forgotPassword.name,
      metaTitle: '',
      hideToolbars: true
    }
  },
  {
    path: RouteNames.resetPassword.path,
    loadChildren: () => import('./features/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    data: {
      name: RouteNames.resetPassword.name,
      metaTitle: '',
      hideToolbars: true
    }
  },
  {
    path: RouteNames.signUp.path,
    loadChildren: () => import('./features/sign-up/sign-up.module').then((m) => m.SignUpModule),
    data: {
      name: RouteNames.signUp.name,
      metaTitle: '',
      hideToolbars: true
    }
  },
  {
    path: RouteNames.dashboard.path,
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: {
      name: RouteNames.dashboard.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard]
  },
  {
    path: RouteNames.events.path,
    loadChildren: () => import('./features/events/events.module').then((m) => m.EventsModule),
    data: {
      name: RouteNames.events.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.reports.path,
    loadChildren: () => import('./features/reports/reports.module').then((m) => m.ReportsModule),
    data: {
      name: RouteNames.reports.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.notification.path,
    loadChildren: () => import('./features/notification/notification.module').then((m) => m.NotificationModule),
    data: {
      name: RouteNames.notification.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard]
  },
  {
    path: RouteNames.clientList.path,
    loadChildren: () => import('./features/client-list/client-list.module').then((m) => m.ClientListModule),
    data: {
      name:RouteNames.clientList.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.userList.path,
    loadChildren: () => import('./features/user-list/user-list.module').then((m) => m.UserListModule),
    data: {
      name: RouteNames.userList.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.rolesList.path,
    loadChildren: () => import('./features/role-list/role-list.module').then((m) => m.RoleListModule),
    data: {
      name: RouteNames.rolesList.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.roleProfile.path,
    loadChildren: () => import('./features/role-profile/role-profile.module').then((m) => m.RoleProfileModule),
    data: {
      name: RouteNames.roleProfile.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.featureFlag.path,
    loadChildren: () => import('./features/feature-flag/feature-flag-list.module').then((m) => m.FeatureFlagListModule),
    data: {
      name: RouteNames.featureFlag.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.clientProfile.path,
    loadChildren: () => import('./features/client-profile/client-profile.module').then((m) => m.ClientProfileModule),
    data: {
      name: RouteNames.clientProfile.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.userProfile.path,
    loadChildren: () => import('./features/user-profile/user-profile.module').then((m) => m.UserProfileModule),
    data: {
      name: RouteNames.userProfile.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.export.path,
    loadChildren: () => import('./features/export/export.module').then((m) => m.ExportModule),
    data: {
      name: RouteNames.export.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.providerList.path,
    loadChildren: () => import('./features/provider-list/provider-list.module').then((m) => m.ProviderListModule),
    data: {
      name:RouteNames.providerList.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.providerProfile.path,
    loadChildren: () => import('./features/provider-profile/provider-profile.module').then((m) => m.ProviderProfileModule),
    data: {
      name: RouteNames.providerProfile.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: RouteNames.goalPayerSetting.path,
    loadChildren: () => import('./features/goal-payer-setting/goal-payer-setting-list.module').then((m) => m.GoalPayerSettingListModule),
    data: {
      name: RouteNames.goalPayerSetting.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.resourcesList.path,
    loadChildren: () => import('./features/resources/resources-list.module').then((m) => m.ResourcesListModule),
    data: {
      name:RouteNames.resourcesList.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard,PermissionGuard]
  },
  {
    path: RouteNames.resourceDetail.path,
    loadChildren: () => import('./features/resource-detail/resource-detail.module').then((m) => m.ResourceDetailModule),
    data: {
      name: RouteNames.resourceDetail.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: RouteNames.userAccount.path,
    loadChildren: () => import('./features/user-account/user-account.module').then((m) => m.UserAccountModule),
    data: {
      name: RouteNames.userAccount.name,
      metaTitle: '',
      hideToolbars: false
    },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
    data: {
      metaTitle: '',
      hideToolbars: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
