export const RouteNames = {
    login: { name: 'login', path: 'login' },
    forgotPassword: { name: 'forgotPassword', path: 'forgot-password' },
    resetPassword: { name: 'resetPassword', path: 'reset-password' },
    signUp: { name: 'signUp', path: 'sign-up' },
    dashboard: { name: 'dashboard', path: 'dashboard' },
    events: { name: 'events', path: 'events' },
    reports: { name: 'reports', path: 'reports' },
    notification: { name: 'notification', path: 'notification' },
    clientList: { name: 'clientList', path: 'client/list' },
    userList: { name: 'userList', path: 'hh-admin/user/list' },
    rolesList: { name: 'rolesList', path: 'hh-admin/roles/list' },
    roleProfile: { name: 'roleProfile', path: 'hh-admin/roles/profile' },
    featureFlag: { name: 'featureFlag', path: 'feature-flag' },
    clientProfile: { name: 'clientProfile', path: 'client/profile' },
    userProfile: { name: 'userProfile', path: 'hh-admin/user/profile' },
    export:{ name: 'export', path: 'hh-admin/export/all' },
    providerList: { name: 'providerList', path: 'provider/list' },
    providerProfile: { name: 'providerProfile', path: 'provider/profile' },
    goalPayerSetting: { name: 'goalPayerSetting', path: 'hh-admin/goal-payer-setting' },
    resourcesList: { name: 'resourcesList', path: 'resources' },
};