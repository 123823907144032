import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SideNavModule } from './libs/side-nav/side-nav.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TopNavModule } from './libs/top-nav/top-nav.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { BannerModule } from './libs/banner/banner.module';
import { SnackbarModule } from './libs/snackbar/snackbar.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppInitializerService } from './services/app-initializer.service';
import { RootStore } from './store/root.store';
import { appInitializerFactory } from './factories/app-initializer.factory';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        SideNavModule,
        MatTooltipModule,
        TopNavModule,
        MatProgressBarModule,
        BannerModule,
        SnackbarModule,
        MatSnackBarModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [AppInitializerService, RootStore],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
